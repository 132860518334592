@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.container {
    display: block;
    margin: auto;
}
.container-fluid {
    width: 100%;
}
.fixed-top {
    position: fixed;
    top: 0;
    background-color: #fff;
    border-bottom: 2px solid #f5f5f5;
    z-index: 9999999;
}
.row {
    display: flex;
}
.row-1140 {
    max-width: 1140px;
    margin: auto;
    padding: 0 0.54rem;
    @media (max-width: 1200px) {
        padding: 0 3rem;
    }
    @media (max-width: 768px) {
        padding: 0 2rem;
    }
}
.top-line {
    width: 100%;
    height: 1.45rem;
    background-color: $gray-background-color;
}
.text-center {
    text-align: center !important;
}
.title {
    font-size: 2.5rem;
    color: $primary-color;
    font-weight: 300;
    width: 100%;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 1.5rem;
    }
}
.subtitle {
    font-size: 1.25rem;
    margin: 1.2rem 0;
    color: $dark-purple;
    @media (max-width: 600px) {
        font-size: 1.1rem;
    }
}

.subtitle-bold {
    margin: 3rem 0;
    font-weight: 500;
    text-align: center;
    font-size: 1.5rem;
}

.subtitle3 {
    font-size:1.14rem;
    text-transform: uppercase;
    color: $dark-purple;
    width: 100%;
    text-align: center;
}
.segment-section {
    padding: 6.2rem 0;
}

.box-text {
    width: 65%;
    margin:0 auto;
    @media (max-width: 768px) {
        width: 98%;
    }
}

.box-text-full {
    width: 100%;
    margin:0 auto;
}

.text-page {
    color: $basic-text-color;
    font-weight: 300;
    font-size: 1.15rem;
    padding: 0.8rem 0;
    margin: 18px 0;

    strong {
        color: $basic-text-color;
    }

    small {
        line-height: 1rem;
        display: block;
    }
}

.box-assets {
    margin: 2rem 0;
    justify-content: space-between;
    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
    div {
        width: 46%;
        align-items: flex-start;
        @media (max-width: 600px) {
            width: 100%;
        }
        img {
            width: 2rem;
            display: block;
            float: left;
            margin-right: 1rem;
            margin-top: 0.5rem;
        }
        ._big {
            width: 2.5rem;
        }
        p {
            font-weight: 300;
            font-size: 1rem;
            strong {
                color: $dark-purple;
                font-size: 1.15rem;
            }
        }
    }
}
.space {
    width: 100%;
    height: 1.5rem;
    @media (max-width: 600px) {
        height: 0;
    }
}
.bg-grey {
    background-color: $gray-background-color;
}
.bt-cta {
    margin: 1rem auto 0 !important;
}
.box-image {
    width: 100%;
    img {
        display: block;
        margin: 0 auto;
        width: 80.7%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
}
.row-960 {
    max-width: 960px;
    margin: auto;
}

.container-960 {
    max-width: 960px;
}

.container-650 {
    max-width: 650px;
}

.bt {
    display: table;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    border-radius: $radius;
    transition: $transition;
    border: none;
    
    font-weight: 500;
    text-align: center;
    color: #fff;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    }

    &:focus {
        outline: 0;
    }

    
    .mdi {
        display: inline-block;
        margin-right: 0.5rem;
        font-size:1.3rem;
        vertical-align: middle;
    }
}
.support {
    padding:0 0 6.5rem;
    .row-1140 {
    border: 2px solid $primary-color;
    padding: 3rem 0.54rem;
        @media (max-width: 1024px) {
            max-width: 90%;;
        }
        @media (max-width: 600px ) {
            max-width: 100%;
        }
        h2 {
            font-size: 2rem;
            margin-bottom: 3rem;
        }
        .bt-full {
            background-color: $primary-color;
        }
    }

}
.bt-stroke {
    background: #fff;
    color: $primary-color !important;
    border: 2px solid $primary-color;
    padding: 0.5rem 1.5rem;
    min-width: 160px;
    margin: 0.8rem;
    font-size: 1rem;
}

.bt-full {
    background-color: $cta;
    margin: 1rem auto;
    padding: 1rem 1.2rem;
    min-width: 180px;
    font-size: 1rem;
}

.bt-login {
    background-color: $dark-purple;
    margin: 1rem auto;
    padding: 1.5rem 2rem;
    width: 100%;
}

.bt-left {
    margin: 1rem 0;
}
.alert-box {
    padding: 1rem;
    margin: 1rem;
    margin-bottom: 0;
    background-color: #eee;

    p {
        text-align: center;
        margin-bottom: 0;
    }
}


.list-menu {
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding: 0;
    margin: 0.65rem 0;
    transition: all 500ms;

    * {
        font-size: 0.85rem;
        color: $primary-color;
    }
    li {
        margin: 0.8rem 0;
    }

    li a {
        display: flex;
        align-items: center;
        transition: all 500ms;
    
        img {
            margin-right: 0.5rem;
        }
        &:hover {
            color: $dark-purple;
        }
    }
    li p {
        font-weight: 500;
    }
    li p span {
        color: $grey-text-color;
        font-weight: 300;
    }
}

.list-menu-resp {
    display: flex;
    flex: 1;
    flex-direction: column;
    list-style: none;
    justify-content: space-between;
    padding: 0.5rem;
    margin: 0;
    transition: all 500ms;
    background-color: $primary-color;

    * {
        font-size: 0.8rem;
        color: $link-color-on-black;
    }

    li a {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        transition: all 500ms;
        border-bottom: 1px solid $dark-purple;
    
        img {
            margin-right: 0.5rem;
        }

        &:hover {
            color: $link-color-hover-on-black;
        }
    }
}

.open {
    display: flex !important;
    flex: 1;
}

.close {
    display: none !important;
}

.list-menu-column {

    li {
        a {
            background-color: $link-background-color;
            border-bottom: #333 1px solid;
            text-align: center;
            transition: all 500ms;
            font-size: 1rem;

            &:hover {
                background-color: $link-background-color-hover;
            }
        }
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.btn {
    display: block;
    width: 100%;
    text-align: center;
    background-color: $link-background-color;
    padding: 0.8rem;
    border-radius: 0.5rem;
    color: #fff;
    transition: all 500ms;

    &:hover {
        background-color: $link-background-color-hover;
        color: #fff;
    }
    
    
}


.link-article {
    text-align: right;
    display: block;
    color: $link-color;
    font-size: 1.2rem;
    transition: all 500ms;

    &:hover {
        color: $link-color-hover;
    }
}

.active {
    background-color: $link-background-color-hover;
    color: #fff;
}

.mat-tab-header {
    display: none !important;
  }


  .box-default {
    border: 1px solid #cfcfcf;
    padding: 1rem;
    margin: 1rem;

    header {
        background-color: #333;
        color: #fff;
        text-align: center;
    }
  }

  .striped-table {
      width: 100%;

      tr {
        
        
        td {
            padding: 0.5rem 1rem;

            table {
                width: 100%;

                tr td {
                    padding: 0 0.5rem;
                }


            }
        }

        td.text-center {
            text-align: center;
        }

        td:nth-child(2) {
            text-align: right;
        }
      }

    tr:nth-child(even) {
        background-color: #f2f2f2;
      }
  }

  .striped-list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        padding: 0.5rem 1rem;
    }
    
  li:nth-child(even) {
      background-color: #f2f2f2;
    }
}
.no-pd-r {
    padding-right: 0!important;
}

.container-video {
    display: block;
  
    .banner-video {
        max-width: 500px;
        margin: 10px 0;
        margin: auto;
  
        @media (max-width: 425px) {
            width: 100%;
        
        }
  
        .banner-item-video {
            padding: 0 0 56.25%;
            position: relative;
            height: 0;
    
            iframe {
                padding: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
  }
